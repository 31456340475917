import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Link from '../components/LocalizedLink';

import withLayout from '../layout';
import SEO from '../components/seo';

import "./contact.scss";

const Success = (props) => {
    const { intl } = props;
    const titleText = intl.formatMessage({id: 'contact.success-title'});

    return (
        <div>
            <SEO title={titleText} />
            <article className="contact-success">
                <div className="container">
                    <div className="contact-success__header">
                        <h1><FormattedMessage id="contact.success-thanks" /></h1>
                        <p><FormattedMessage id="contact.success-response" /></p>
                        <Link to="/" className="contact-success__cta"><FormattedMessage id="contact.success-back" /></Link>
                    </div>
                </div>
            </article>
        </div>
    );
};

const customProps = {
    localeKey: 'contact',
};

export default withLayout(customProps)(injectIntl(Success));
